/**
 * Created by fworld on 2019/9/20.
 */
import request from '@/util/request'

export const deviceUnion = (params) => {
    return request({
        url: '/api/v1/factory/device/delivery/union',
        method: 'get',
        params
    })
}


export const deviceList = (params) => {
    return request({
        url: '/api/v1/factory/device/deliveryList',
        method: 'get',
        params
    })
}

export const deviceAllocate = (data) => {
    return request({
        url: '/api/v1/factory/device/allocate',
        method: 'post',
        data
    })
}

