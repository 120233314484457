<template>
  <div class="card-wrapper">
    <p class="back" @click="$router.back()"><i class="el-icon-back"></i> 返回</p>
    <div class="card">
      <p class="title:">
        产品名称: {{product.name}}
      </p>

      <div class="card-left">

        <el-form label-width="64px" :inline="true">

          <el-form-item label="产品编号:">
            {{product.uuid}}
          </el-form-item>

          <el-form-item label="产品Key:">
            {{product.productKey}}
          </el-form-item>
          <el-form-item label="产品密钥:">
            {{product.productSecret}}
          </el-form-item>

          <el-form-item label="创建时间:">
            {{new Date(product.createdAt * 1000).Format('yyyy-mm-dd hh:MM:ss')}}
          </el-form-item>
        </el-form>
      </div>
    </div>

    <div class="card card-board">
      <el-tabs v-model="activeName">
        <el-tab-pane label="版本列表" name="versions">

        </el-tab-pane>
        <el-tab-pane label="产品定义" name="define"></el-tab-pane>
      </el-tabs>
      <div v-show="activeName === `versions`">
        <div class="title">
          <span>产品版本</span>

          <p style="position: relative;float: right;padding-left: 10px;">
            <span @click="handleAddVersion" class="btn">
             <i class="iconfont iconfabu"></i> 上传新版本
            </span>
            <span @click="handlePublishDefault" class="btn">
             <i class="iconfont iconyunduanxiazai"></i> 默认发布
            </span>
            <span> <i class="dot"></i>
            默认发布可将无版本设备绑定默认版本</span>
          </p>
        </div>
        <el-table :data="versions" height="320px">
          <el-table-column label="版本号" align="center">
            <template slot-scope="scope">
              {{scope.row.uuid}} <span v-if="scope.row.uuid === product.defaultVersionUuid" class="default">默认</span>
            </template>
          </el-table-column>
          <el-table-column label="软件版本号" align="center" prop="version"></el-table-column>
          <el-table-column label="描述信息" prop="description"></el-table-column>
          <el-table-column label="创建时间" align="center">
            <template slot-scope="scope">
              {{ new Date(scope.row.createdAt * 1000).Format('yyyy-MM-dd hh:mm:ss')}}
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <span @click="handleVersionBindDevice(scope.row)"
                    class="research-fault-bind">绑定设备</span>
              <span @click="handleVersionDefault(scope.row)"
                    v-show="(scope.row.uuid !== product.defaultVersionUuid)"
                    class="research-fault-edit">设为默认</span>
              <span @click="handleDelete(scope.row, scope.$index)"
                    class="research-fault-delete">删除版本</span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @current-change="handleCurrentChange"
          class="pagination"
          :current-page="pagination.curPage"
          :page-size="pagination.limit"
          layout="total, prev, pager, next, jumper"
          :total="pagination.total">
        </el-pagination>
      </div>

      <div v-show="activeName === `define`">
        <recycle-item :nodes.sync="items"
                      :edit.sync="isEdit"
                      class="recycle-item"
                      :depth="0">
        </recycle-item>
        <div class="operate-margin">
          <el-button size="mini" type="primary" @click="handleAddItem">添加新项</el-button>
          <el-button size="mini" type="primary" @click="isEdit = !isEdit">编辑信息</el-button>
          <el-button size="mini" type="primary" @click="handleSaveProfile">保存配置</el-button>
        </div>

      </div>


    </div>
    <c-dialog class="dialog" :visible.sync="addVersionVisible" :width="'50rem'" :height="'37.5rem'" title="上传版本">
      <div class="version-dialog">
        <upload :url.sync="version.upgrade" :fileShowType="4" :isMd5="true" class="upload"
                @upload-success="fileUploadSuccess"></upload>
        <div class="version-dialog-body clearfix">
          <el-form class="base-info" label-width="100px">
            <el-form-item label="版本信息">
              <el-input v-model="version.version" disabled></el-input>
            </el-form-item>
          </el-form>
        </div>
        <el-form class="ctrl-info" label-width="100px">
          <el-form-item label="版本说明">
            <el-input v-model="version.description" type="textarea" :rows="3"></el-input>
          </el-form-item>
        </el-form>
        <div class="dialog-commit-btn">
          <el-button type="primary" @click="handleVersionCreate">提交</el-button>
        </div>
      </div>

    </c-dialog>

    <c-dialog class="dialog" :visible.sync="allocatedVisible" :width="'50rem'" :height="'37.5rem'"
              :outer-close="false"
              title="分配设备">

      <el-button slot="title" size="mini" type="primary" @click="deviceAllocate">保存</el-button>

      <allocation-device :close="closeAllocated" ref="allocDevice" slot="default"></allocation-device>
    </c-dialog>
  </div>

</template>

<script>
  import CDialog from '@/components/Dialog'
  import RecycleItem from '@/components/RecycleItem'
  import AllocationDevice from '@/components/AllocateDevice'
  import {
    versionUnion,
    versionCreate,
    versionDefault,
    versionDelete,
    versionList,
    versionBindDevice
  } from '@/api/factory/research/version';
  import {
    productConf,
    productConfUpdate
  } from '@/api/factory/research/product';

  import Upload from '@/components/Upload'
  import {checkObj} from "@/util/checkLogin";


  export default {
    data() {
      return {
        allocatedVisible: false,
        activeName: 'versions',
        versions: [],
        product: {},
        versionUuid: '',
        addVersionVisible: false,
        isEdit: false,
        pagination: {
          productUuid: '',
          start: 0,
          curPage: 1,
          limit: 10,
          total: 0,
        },
        detail: {},
        version: {
          productUuid: '',
          version: "",
          description: '',
          upgrade: '',
          md5: ''
        },
        items: []
      }
    },
    computed: {},
    methods: {

      fileUploadSuccess(data) {
        let version = data.name.substr(0, data.name.lastIndexOf("."))
        this.version.md5 = data.md5
        this.version.upgrade = data.url
        this.version.version = version
      },
      init() {
        let detail = this.$route.params

        if (!detail || !detail.uuid) {
          this.$router.back()
          return
        }

        this.product = detail
        this.pagination.productUuid = detail.uuid
        this.loadVersion()
        this.loadProductConf()
      },
      loadProductConf() {
        productConf(this.pagination.productUuid).then(res => {
          this.items = res.data || []
        })
      },

      handleAddVersion() {
        this.version = {
          productUuid: this.product.uuid,
          version: "",
          description: '',
          upgrade: '',
          md5: ''
        }
        this.addVersionVisible = true
      },

      handleAddItem() {
        this.isEdit = true
        this.items.push({
          name: '',
          variable: '',
          type: 1,
          min: 0,
          max: 0,
          step: 0,
          default: true,
          unitName: '',
          nodes: []
        })
      },

      handleVersionCreate() {
        let status = checkObj(this.version, [
          {value: 'productUuid', message: '请选取产品'},
          {value: 'md5', message: '请上传文件'},
          {value: 'upgrade', message: '请上传文件'},
          {value: 'description', message: '请填写描述'}
        ])
        if (!status.status) {
          this.$message.warning(status.message)
          return
        }
        versionCreate(this.version).then(res => {
          this.loadVersion()
          this.addVersionVisible = false
          this.$message.success('上传成功')
        })
      },

      handlePublishDefault() {

      },
      handleVersionBindDevice(version) {
        this.versionUuid = version.uuid
        let vue = this
        this.allocatedVisible = true
        this.$nextTick(function () {
          vue.$refs.allocDevice.setEnterprise(this.detail)
          vue.$refs.allocDevice.loadDevice(this.product.uuid, true)
        }, 200)
      },

      handleVersionDefault(version) {
        versionDefault(version.uuid).then(res => {
          this.product.defaultVersionUuid = version.uuid
          this.$message.success('设定成功')
        })
      },

      handleDelete(version) {
        if (version.uuid === this.product.defaultVersionUuid) {
          this.$message.error('默认版本不允许删除')
          return
        }
        this.$confirm('此操作将删除指定版本, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          versionDelete(version.uuid).then(res => {
            this.loadVersion()
          })
        }).catch(() => {

        })

      },
      closeAllocated() {
        this.allocatedVisible = false
      },
      deviceAllocate() {
        let vue = this
        let delivery = vue.$refs.allocDevice.getDevice()
        if (delivery.devices.length === 0) {
          this.$message.warning('请选取设备')
        } else {
          versionBindDevice({versionUuid: this.versionUuid, deviceUuid: delivery.devices}).then(res => {
            this.$message.success('分配成功')
            this.allocatedVisible = false
          })
        }
      },
      loadVersion() {
        let vue = this
        versionUnion(vue.pagination.productUuid).then(res => {
          vue.versions = res.data.list || []
          vue.pagination.total = parseInt(res.data.total)
          if (vue.versions.length === 1) {
            vue.product.defaultVersionUuid = this.versions[0].uuid
          }
        })
      },

      handleSaveProfile() {
        if (this.items.length === 0) {
          this.$message.error('请输入产品参数')
          return
        }
        if (!this.isEdit) {
          this.$message.success('更新成功')
          return
        }
        productConfUpdate({uuid: this.pagination.productUuid, nodes: this.items}).then(res => {
          this.$message.success('更新成功')
          this.isEdit = false
          return
        })
      },
      handleCurrentChange(value) {
        this.pagination.curPage = value
        this.pagination.start = (value - 1) * this.pagination.limit
        versionList(this.pagination).then(res => {
          this.versions = res.data
        })
      },
    },
    mounted() {
      this.init()
    },
    activated() {
      this.init()
    },

    components: {
      CDialog,
      AllocationDevice,
      Upload,
      RecycleItem
    }
  }
</script>

<style lang="scss">
  .card-wrapper {
    .card {
      .el-form-item {
        margin-bottom: 35px;
      }

      .el-form-item__label {
        font-size: 12px;
        color: #999;
      }
    }
  }
</style>
<style lang="scss" scoped>
  .card-wrapper {
    padding: $padding;

    .back {
      cursor: pointer;
    }

    .back:hover {
      color: $_theme_color;
    }

    .card-board {
      box-shadow: $-box-shadow;
    }


    .card {
      border-radius: $border-radius;
      position: relative;
      color: $font-color-shallow6;
      padding: $padding;

      .el-form-item {
        margin-bottom: 0;
      }

      .recycle-item {
        min-height: 320px;
      }

      .save-btn {
        position: absolute;
        right: 35%;
        bottom: $padding;
      }

      .title {
        font-size: 12px;
        margin-bottom: $margin;
      }

      span {
        color: #333;
      }

      .btn {
        cursor: pointer;
        margin-right: $padding-8;
        color: $_theme_color;

      }

      .text {
        min-height: 30px;
        padding-left: 90px;
        position: relative;
        line-height: 30px;
        font-size: 12px;
        color: #333;

        span {
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          color: #999;
          text-align: right;
          width: 80px;
        }
      }


      .dot {
        margin-left: $padding-8;
        display: inline-block;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: $-theme-color;

      }

      .default {
        font-size: .6rem;
        border: 1px solid $_theme_color;
        color: $_theme_color;
        padding: 2px 4px;
        border-radius: 2px;
      }

      .research-fault-edit, .research-fault-delete, .research-fault-bind {
        cursor: pointer;
        display: inline-block;
        margin-right: $padding;
      }

      .research-fault-bind {
        color: $_theme_color;
      }

      .research-fault-edit {
        color: $btn_color;
      }

      .research-fault-delete {
        color: $btn_sec_color;
      }
    }

    .card:after {
      content: ".";
      display: block;
      height: 0;
      clear: both;
      visibility: hidden;
    }

    .dialog-commit-btn {
      text-align: center;
    }


    .operate-margin {
      margin-top: $padding-4;
    }


  }

  body.light, body.dark {
    .version-dialog {
      .el-upload-dragger {
        width: 100% !important;
      }
    }
  }
</style>
