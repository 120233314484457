import request from '@/util/request'


export const versionUnion = (productUuid) => {
    return request({
        url: `/api/v1/factory/version/union?uuid=${productUuid}`,
        method: 'GET',
    })
}

export const versionCreate = (data) => {
    return request({
        url: `/api/v1/factory/version/create`,
        method: 'POST',
        data
    })
}

export const versionDefault = (uuid) => {
    return request({
        url: `/api/v1/factory/version/default?uuid=${uuid}`,
        method: 'GET',
    })
}

export const versionDelete = (uuid) => {
    return request({
        url: `/api/v1/factory/version/delete?uuid=${uuid}`,
        method: 'GET',
    })
}

export const versionList = (params) => {
    return request({
        url: `/api/v1/factory/version/list`,
        method: 'GET',
        params
    })
}

export const versionBindDevice = (data) => {
  return request({
    url: `/api/v1/factory/version/bindDevice`,
    method: 'POST',
    data
  })
}
