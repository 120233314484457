/**
 * Created by fworld on 2019/9/20.
 */
import request from '@/util/request'

/**
 * 企业产品列表
 * @returns {AxiosPromise}
 */
export const enterpriseUnion = (params) => {
  return request({
    url: `/api/v1/factory/enterprise/union`,
    method: 'get',
    params
  })
}

export const enterpriseCreate = (data) => {
  return request({
    url: `/api/v1/factory/enterprise/register`,
    method: 'post',
    data
  })
}


export const enterpriseAuditUnion = (params) => {
  return request({
    url: `/api/v1/factory/enterprise/audit/union`,
    method: 'get',
    params
  })
}

export const enterpriseAuditList = (params) => {
  return request({
    url: `/api/v1/factory/enterprise/audit/list`,
    method: 'get',
    params
  })
}

export const enterpriseAuditPass = (params) => {
  return request({
    url: `/api/v1/factory/enterprise/audit/pass`,
    method: 'get',
    params
  })
}

export const enterpriseModules = (params) => {
  return request({
    url: `/api/v1/account/enterprise/modules`,
    method: 'get',
    params
  })
}

export const moduleClose = (params) => {
  return request({
    url: `/api/v1/account/enterprise/closeModule`,
    method: 'get',
    params
  })
}

export const moduleOpen = (uuid, data) => {
  return request({
    url: `/api/v1/account/enterprise/openModule?uuid=${uuid}`,
    method: 'post',
    data
  })
}


export const enterpriseAllName = () => {
  return request({
    url: `/api/v1/factory/enterprise/allName`,
    method: 'get',
  })
}

export function enterpriseCtrl(params) {
  return request({
    url: `/api/v1/factory/enterprise/ctrl`,
    method: `get`,
    params
  })
}
