<template>
  <div class="recycle-item">
    <div class="variable">
      <div class="variable-line title" v-if="depth ===0">
        <div class="variable-line-item tf flex2">名称</div>
        <div class="variable-line-item tf flex2">变量名</div>
        <div class="variable-line-item">变量值类型</div>
        <div class="variable-line-item">可选范围</div>
        <div class="variable-line-item">变量步长</div>
        <div class="variable-line-item">默认值</div>
        <div class="variable-line-item">补充说明</div>
        <div class="variable-line-item">操作</div>
      </div>
    </div>
    <div v-if="nodes.length >0" class="variable">
      <template v-if="!edit">
        <div v-for="node in nodes" class="variable-line variable-content" :class="(node.type  === 2)?'variable-div':''">
          <div class="variable-line-item tf flex2" :style="indent">{{node.name}}</div>
          <div class="variable-line-item tf flex2" :style="indent">{{node.variable}}</div>
          <div class="variable-line-item">{{node.type | filterType}}</div>
          <div class="variable-line-item" v-if="node.type === 0">{{node.min}}~{{node.max}}</div>
          <div class="variable-line-item" v-else>-</div>
          <div class="variable-line-item" v-if="node.type === 0">{{node.step}}</div>
          <div class="variable-line-item" v-else>-</div>
          <div class="variable-line-item" v-if="node.type  !== 2">{{node.default}}</div>
          <div class="variable-line-item" v-else>-</div>
          <div class="variable-line-item" v-if="node.type  !== 2">{{node.unitName}}</div>
          <div class="variable-line-item" v-else>-</div>
          <div class="variable-line-item tr">
            <span class="edit-child" v-show="node.type  === 2"><i
              class="iconfont iconzuzhiqunzu"></i></span>
          </div>
          <recycle-item v-if="node.nodes" :nodes="node.nodes"
                        :depth="depth + 1" :edit.sync="edit"></recycle-item>
        </div>
      </template>

      <template v-else>
        <div v-for="(node, index) in nodes" class="variable-line variable-content"
             :class="(node.type  === 2)?'variable-div':''">
          <div class="variable-line-item tf flex2" :style="indent">
            <el-input v-model="node.name" placeholder="请输入变量名称" size="mini"></el-input>
          </div>
          <div class="variable-line-item tf flex2" :style="indent">
            <el-input v-model="node.variable" placeholder="请输入变量名" size="mini"></el-input>
          </div>
          <div class="variable-line-item">
            <el-select v-model="node.type" size="mini">
              <el-option v-for="type in types" :value="type.value" :key="type.value" :label="type.name"></el-option>
            </el-select>
          </div>
          <div class="variable-line-item" v-if="node.type === 0">
            <el-row>
              <el-col :span="12">
                <el-input v-model.number="node.min" type="number" placeholder="请输入变量最小值" size="mini"></el-input>
              </el-col>
              <el-col :span="12">
                <el-input v-model.number="node.max" type="number" placeholder="请输入变量最大值" size="mini"></el-input>
              </el-col>
            </el-row>
          </div>
          <div class="variable-line-item" v-else>-</div>
          <div class="variable-line-item" v-if="node.type === 0">
            <el-input v-model.number="node.step" type="number" placeholder="请输入变量步长" size="mini"></el-input>
          </div>
          <div class="variable-line-item" v-else>-</div>
          <div class="variable-line-item" v-if="node.type  !== 2">
            <el-input v-model.number="node.default" type="number" placeholder="请输入变量默认值" size="mini"
                      v-if="node.type ===0"></el-input>
            <el-switch v-model="node.default" placeholder="请输入变量默认值" size="mini" v-if="node.type ===1"
                       :active-value="'true'" :inactive-value="'false'"></el-switch>
          </div>
          <div class="variable-line-item" v-else>-</div>
          <div class="variable-line-item" v-if="node.type  !== 2">
            <el-input v-model="node.unitName" placeholder="请输入变量说明" size="mini"></el-input>
          </div>
          <div class="variable-line-item" v-else>-</div>
          <div class="variable-line-item tr">
            <span class="edit-child" title="添加下级" v-show="node.type  === 2" @click="handleAddChild(nodes, index, node)"><i
              class="iconfont iconzuzhiqunzu"></i></span>
            <span class="edit-info" title="复制" @click="handleCopyChild(nodes, index, node)"><i
              class="iconfont iconfuzhi"></i></span>
            <span class="delete margin" title="删除" @click="handleRemoveChild(nodes, index)"><i
              class="iconfont iconshanchu1"></i></span>
          </div>
          <recycle-item v-if="node.nodes" :nodes="node.nodes"
                        :depth="depth + 1" :edit.sync="edit"></recycle-item>
        </div>
      </template>
    </div>
  </div>

</template>

<script>
  export default {
    name: 'recycle-item',
    props: ['nodes', 'depth', 'edit'],
    components: {},
    mounted: function () {
    },
    computed: {
      indent() {
        return {transform: `translate(${this.depth * 10}px)`}

      },
    },
    filters: {
      filterType(type) {
        switch (type) {
          case 0:
            return '整数值'
          case 1:
            return '布尔值'
          case 2:
            return '组合参数'
        }
      }
    },
    data: function () {
      return {
        types: [
          {name: '整数值', value: 0},
          {name: '布尔值', value: 1},
          {name: '组合参数', value: 2},

        ]
      }
    },
    methods: {
      handleAddChild(nodes, index, node) {
        node.nodes = Object.assign([], node.nodes, node.nodes || [])
        let n = {
          name: '',
          variable: '',
          type: 0,
          min: 0,
          max: 0,
          step: 1,
          default: 0,
          unitName: '',
          nodes: []
        }
        node.nodes.push(n)
        this.$set(node.nodes, node.nodes.length - 1, n)
        this.$set(this.nodes, index, node)
        this.$emit('update:nodes', nodes)
      },
      handleRemoveChild(nodes, index) {
        if (nodes[index].type === 2 && nodes[index].nodes && nodes[index].nodes.length > 0) {
          this.$confirm('是否删除该项', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            nodes.splice(index, 1)
          })
        } else {
          nodes.splice(index, 1)
        }
      },
      deepCopy(obj) {
        if(!obj){
          return
        }
        let temp = obj.constructor === Array ? [] : {}
        for (let val in obj) {
          temp[val] = typeof obj[val] == 'object' ? this.deepCopy(obj[val]) : obj[val]
        }
        return temp
      },
      handleCopyChild(nodes, index, node) {

        nodes.splice(index, 0, this.deepCopy(node))
        this.$set(nodes, index + 1, this.deepCopy(node))
        this.$emit('update:nodes', nodes)
      }
    },
  }
</script>

<style lang="scss">
  .recycle-item {
    width: 100%;

    .variable {
      &-line {
        display: flex;
        flex-flow: row wrap;

        &-item {
          display: inline-block;
          flex: 0 0 12.5%;
          text-align: center;

          .el-input {
            padding-right: $padding;
            width: auto;
            input{
              padding-right: 0;
            }
          }

          span {
            cursor: pointer;
            display: inline-block;
            font-size: 12px;
            margin: 0 8px;
          }

          span:hover {
            font-weight: 500;
          }

          .edit-child {
            color: $_theme_color;
          }

          .edit-info {
            color: $btn_color;
          }

          .delete {
            color: $btn_sec_color;
          }
        }

        .tf {
          text-align: left;
        }

        .tr {
          text-align: right;
        }

        .margin {
          margin-right: $padding;
        }

      }

      .title {
        margin: 8px 0;
      }

      .flex2 {
        flex: 2;
      }

      &-content {
        .variable-line-item {
          font-size: 13px;
          line-height: 2;
        }
      }

      &-div:before {
        content: "";
        position: relative;
        margin-bottom: $padding-4;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        z-index: 1;
        background-color: $-theme-light-6;
      }
    }
  }
</style>
