<template>
  <div class="allocated-devices">
    <el-form class="allocated-device-title" label-width="72px" :inline="true" size="mini">
      <el-col :span="8">
        <el-form-item label="所属产品:">
          <el-select placeholder="请选取对应的产品" class="allocated-device-input-filter"
                     @change="loadDeviceSearch"
                     :disabled="lock"
                     v-model="pagination.productUuid">
            <el-option v-for="item in factoryProductNames"
                       :key="item.uuid"
                       :label="item.name"
                       :value="item.uuid"></el-option>
          </el-select>
        </el-form-item>
      </el-col>

      <el-col :span="8">
        <el-form-item label="所属企业:">
          <el-select placeholder="请选取对应经销商" class="allocated-device-input-filter"
                     @change="loadDeviceSearch"
                     v-model="pagination.enterpriseUuid">
            <el-option v-for="item in deliveries"
                       :key="item.uuid"
                       :label="item.name"
                       :value="item.uuid"></el-option>
          </el-select>
        </el-form-item>
      </el-col>

      <el-col :span="8">
        <el-form-item label="IEMI搜索:">
          <el-input placeholder="请输入设备条码" class="allocated-device-input-filter"
                    @change="loadDeviceSearch"
                    v-model="pagination.iemi"
                    prefix-icon="el-icon-search"></el-input>
        </el-form-item>
      </el-col>
    </el-form>
    <div class="allocated-device-data-list">
      <el-table :data="devices"
                max-height="360px"
                @selection-change="handleBatch">
        <el-table-column type="selection">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.checked"
                         @change="change(scope.row)"></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column label="设备编号" align="center" prop="uuid"></el-table-column>
        <el-table-column label="所属经销商" align="center" prop="enterpriseName"></el-table-column>
        <el-table-column label="iemi" align="center" prop="deviceId"></el-table-column>
        <el-table-column label="产品/版本号" align="center" width="200">
          <template slot-scope="scope">
            {{scope.row.productName}}/{{scope.row.versionUuid}}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      @current-change="handleCurrentChange"
      class="pagination"
      :current-page="pagination.curPage"
      :page-size="pagination.limit"
      layout="total, prev, pager, next, jumper"
      :total="pagination.total">
    </el-pagination>
  </div>

</template>

<script>
  import {deviceUnion, deviceList, deviceAllocate} from '@/api/factory/device/device'
  import {enterpriseAllName} from '@/api/factory/account/enterprise'
  import {mapGetters, mapActions} from 'vuex'

  export default {
    name: '',
    props: {
      close: {
        type: Function,
      }
    },
    components: {},
    computed: {
      ...mapGetters(['factoryProductNames'])
    },
    mounted: function () {
      this.loadDevice()
      this.loadProductNames()
      this.loadEnterprise()
    },
    data: function () {
      return {
        deliveries: [],
        devices: [],
        pagination: {
          productUuid: '',
          enterpriseUuid: '',
          iemi: '',
          start: 0,
          curPage: 1,
          limit: 10,
          total: 0,
        },
        lock: false,
        allocatedEnterprise: {},
        delivery: {
          enterpriseUuid: '',
          devices: []
        }
      }
    },

    methods: {
      ...mapActions(["loadProductNames"]),

      setEnterprise(enterprise) {
        this.allocatedEnterprise = enterprise
        this.delivery.enterpriseUuid = enterprise.uuid
      },
      handleCurrentChange(value) {
        this.pagination.curPage = value
        this.pagination.start = (value - 1) * this.pagination.limit
        deviceList(this.pagination).then(res => {
          this.devices = this.judoCheck(res.data || [])
        })
      },

      judoCheck(devices) {
        for (let j = 0; j < devices.length; j++) {
          devices[j].checked = false
        }
        for (let j = 0; j < devices.length; j++) {
          for (let i = 0; i < this.delivery.devices.length; i++) {
            if (devices[j].uuid === this.delivery.devices[i]) {
              devices[j].checked = true
              break
            }
          }
        }
        return devices
      },

      handleBatch(value) {
        if (value.length !== 0) {
          for (let j = 0; j < this.devices.length; j++) {
            if (!this.devices[j].checked) {
              this.delivery.devices.push(this.devices[j].uuid)
              this.devices[j].checked = true
            }

          }
        } else {
          for (let j = 0; j < this.devices.length; j++) {
            this.devices[j].checked = false
            this.change(this.devices[j])
          }
        }
      },

      change(value) {
        if (value.checked) {
          this.delivery.devices.push(value.uuid)
        } else {
          for (let i = 0; i < this.delivery.devices.length; i++) {
            if (value.uuid === this.delivery.devices[i]) {
              this.delivery.devices.splice(i, 1)
            }
          }
        }
      },

      loadEnterprise() {
        let vue = this
        enterpriseAllName().then(res => {
          vue.deliveries = res.data || []
        })
      },

      loadDevice(productUuid = '', lock = false) {
        let vue = this
        this.lock = lock
        this.pagination.productUuid = productUuid
        deviceUnion(this.pagination).then(res => {
          vue.devices = vue.judoCheck(res.data.list || [])
          vue.pagination.total = parseInt(res.data.total)
        })
      },
      loadDeviceSearch() {
        let vue = this
        this.pagination.curPage = 1
        this.pagination.start = 0
        deviceUnion(this.pagination).then(res => {
          this.delivery.devices = []
          vue.devices = vue.judoCheck(res.data.list || [])

          vue.pagination.total = parseInt(res.data.total)
        })
      },

      getDevice() {
        return this.delivery
      },

      deviceAllocate() {
        if (!this.delivery.devices.length) {
          this.$message.warning('请选取需要分配的设备')
        } else {
          let vue = this
          deviceAllocate(this.delivery).then(() => {
            vue.$message.success('设备分配成功')
            vue.close()
          })

        }

      }
    },
  }
</script>

<style lang="scss">
  .allocated-devices {
    padding: $padding $padding-8;
    display: flex;
    flex-direction: column;

    .allocated-device-title {


      .more {
        font-size: .6rem;
        height: 28px !important;
        line-height: 28px;
        display: table-cell;
        vertical-align: middle;
        text-align: center;

        span {
          cursor: pointer;
        }
      }
    }

    .allocated-device-data-list {
      margin-top: $padding;
      flex: 1;

      .allocated-device-status {
        .allocated-device-info-img {
        }

        .allocated-device-info-detail {

          p {
            margin-bottom: $padding-8;
          }

          p:first-child {
            font-size: .8rem;
          }
        }
      }

      .allocated-device-address {
        p {
          margin-bottom: $padding-8;
        }
      }


    }

    .pagination {
      margin: $padding;
    }
  }

</style>
